<template>
  <div>
    <h2 class="w-100 fs-4 mt-4 text-center">
      {{ $tcust("router." + $route.name) }}
    </h2>
    <router-view v-if="$route.name !== 'Profil'" />
    <div
      v-else
      class="d-flex justify-content-center align-items-center flex-wrap m-auto py-4 px-md-5"
    >
      <MDBContainer class="Profil-container m-0 mb-4">
        <MDBRow class="w-100 m-0">
          <MDBCol
            col="4"
            class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
          >
            {{ $tcust("Profil.name") }}
          </MDBCol>
          <MDBCol
            col="8"
            class="Profil-info d-flex align-items-center"
            :class="$store.state.user.Person.Name ? 'fw-bold' : 'text-muted fst-italic'"
          >
            {{ $store.state.user.Person.Name || $tcust('common.emptyField') }}
          </MDBCol>
        </MDBRow>

        <MDBRow class="w-100 m-0">
          <MDBCol
            col="4"
            class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
          >
            {{ $tcust("Profil.firstName") }}
          </MDBCol>
          <MDBCol
            col="8"
            class="Profil-info d-flex align-items-center"
            :class="$store.state.user.Person.FirstName ? 'fw-bold' : 'text-muted fst-italic'"
          >
            {{ $store.state.user.Person.FirstName || $tcust('common.emptyField') }}
          </MDBCol>
        </MDBRow>

        <MDBRow class="w-100 m-0">
          <MDBCol
            col="4"
            class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
          >
            {{ $tcust("Profil.mailAddress") }}
          </MDBCol>
          <MDBCol
            col="8"
            class="Profil-info d-flex align-items-center align-items-center word-break text-break"
            :class="$store.state.user.Person.MailAddress ? 'fw-bold' : 'text-muted fst-italic'"
          >
            {{ $store.state.user.Person.MailAddress || $tcust('common.emptyField') }}
          </MDBCol>
        </MDBRow>

        <hr class="w-75 mx-auto">

        <MDBRow
          v-if="!($store.state.user.Person.CellPhoneNumber || $store.state.user.Person.PhoneNumber || $store.state.user.Person.FaxNumber)"
          class="w-100 m-0"
        >
          <MDBCol
            col="4"
            class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
          >
            {{ $tcust("Profil.phone") }}
          </MDBCol>
          <MDBCol
            col="8"
            class="Profil-info d-flex align-items-center align-items-center text-muted fst-italic"
          >
            {{ $tcust('common.emptyField') }}
          </MDBCol>
        </MDBRow>

        <div v-else>
          <MDBRow class="w-100 m-0">
            <MDBCol
              col="4"
              class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
            >
              {{ $tcust("Profil.cellPhoneNumber") }}
            </MDBCol>
            <MDBCol
              col="8"
              class="Profil-info d-flex align-items-center align-items-center"
              :class="$store.state.user.Person.CellPhoneNumber ? 'fw-bold' : 'text-muted fst-italic'"
            >
              {{ $store.state.user.Person.CellPhoneNumber || $tcust('common.emptyField') }}
            </MDBCol>
          </MDBRow>

          <MDBRow class="w-100 m-0">
            <MDBCol
              col="4"
              class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
            >
              {{ $tcust("Profil.phoneNumber") }}
            </MDBCol>
            <MDBCol
              col="8"
              class="Profil-info d-flex align-items-center align-items-center"
              :class="$store.state.user.Person.PhoneNumber ? 'fw-bold' : 'text-muted fst-italic'"
            >
              {{ $store.state.user.Person.PhoneNUmber || $tcust('common.emptyField') }}
            </MDBCol>
          </MDBRow>

          <MDBRow class="w-100 m-0">
            <MDBCol
              col="4"
              class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
            >
              {{ $tcust("Profil.faxNumber") }}
            </MDBCol>
            <MDBCol
              col="8"
              class="Profil-info d-flex align-items-center align-items-center"
              :class="$store.state.user.Person.FaxNumber ? 'fw-bold' : 'text-muted fst-italic'"
            >
              {{ $store.state.user.Person.FaxNumber || $tcust('common.emptyField') }}
            </MDBCol>
          </MDBRow>
        </div>

        <hr class="w-75 mx-auto">

        <MDBRow class="w-100 m-0">
          <MDBCol
            col="4"
            class="Profil-subtitle d-flex justify-content-end align-items-center text-muted text-end fst-italic"
          >
            {{ $tcust("Profil.address") }}
          </MDBCol>
          <MDBCol
            col="8"
            class="Profil-info d-flex align-items-center align-items-center"
            :class="$store.state.user.Person.Address.FullAddress ? 'fw-bold' : 'text-muted fst-italic'"
          >
            <Address
              v-if="$store.state.user.Person.Address.FullAdress"
              :address="$store.state.user.Person.Address.FullAddress"
            />
            <span v-else>{{ $tcust('common.emptyField') }}</span>
          </MDBCol>
        </MDBRow>

        <hr class="w-75 mx-auto">

        <MDBRow class="flex-nowrap w-100 m-0">
          <MDBCol class="d-flex flex-row flex-nowrap justify-content-center w-100">
            <div class="fst-italic">
              {{ $tcust("Profil.licenses", { n: $store.state.user.AuthorizedReadOnlyLicenceKeys.length }) }}
            </div>
            <button
              class="btn btn-primary h-fit w-fit ms-3 px-2 py-1"
              @click="$router.push({ name: 'LicensesList' })"
            >
              <span>{{ $tcust("Profil.licensesButton") }}</span>
            </button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div class="Profil-image align-self-start gradient custom-box w-fit m-md-0 my-3">
        <div class="overflow-hidden">
          <Loader v-if="!loaded" class="bg-box" />
          <Image
            v-else
            class="w-fit"
            :image="photo.OriginalImage"
            :alt="$tcust('Profil.img.desc')"
          />          
        </div>
      </div>

      <!-- Update Buttons -->
      <div class="w-100">
        <hr class="w-50 mx-auto mb-4">
        <MDBRow class="flex-nowrap w-100 m-0">
          <MDBCol class="d-flex flex-row flex-nowrap justify-content-center w-100 mb-3">
            <button
              class="btn btn-primary h-fit w-fit ms-3 px-3 py-1"
              @click="$router.push({ name: 'ProfilUpdate' })"
            >
              <span>{{ $tcust("router.ProfilUpdate") }}</span>
            </button>
          </MDBCol>
        </MDBRow>

        <MDBRow class="flex-nowrap w-100 m-0">
          <MDBCol class="d-flex flex-row flex-nowrap justify-content-center w-100 mb-3">
            <button
              class="btn btn-primary h-fit w-fit ms-3 px-3 py-1"
              @click="$router.push({ name: 'ChangePassword' })"
            >
              <span>{{ $tcust("router.ChangePassword") }}</span>
            </button>
          </MDBCol>
        </MDBRow>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-vue-ui-kit";

import Setup from "./ProfilSetup";
const { loaded, photo } = Setup();
</script>

<style lang="scss" scoped>
.Profil-image {
  min-width: 200px;
  max-width: 200px;
}
.Profil-container {
  max-width: 400px;
}
@media (max-width: 359.98px) {
  .Profil-subtitle {
    min-width: 100%;
    justify-content: flex-start !important;
  }
  .Profil-info {
    min-width: 100%;
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;
  }
  hr.w-75 {
    margin-top: 0;
  }
}
</style>