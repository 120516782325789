import { onMounted, ref } from "vue";

import { useStore } from "vuex";

export default function Setup() {
  const store = useStore();

  const loaded = ref(false);
  const photo = ref(null);

  onMounted(async () => {
    photo.value = await store.dispatch("getUserPhoto", store.state.user.UserName.toString())
    .then((res) => photo.value = res)
    .catch(() => photo.value = null)
    .finally(() => loaded.value = true)
  })

  return {
    loaded,
    photo
  }
}